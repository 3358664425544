<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>协议单位管理</el-breadcrumb-item>
            <el-breadcrumb-item>协议单位列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 查询框 -->
      <el-row class="content-box">
         <div class="right-tabbox-newnotice">
            <!--表格类型-->
            <!--            type="border-card"-->
            <el-tabs v-model="activeName" @tab-click="handleClick">
               <el-tab-pane v-for="(item,index) in tabslist"   :key="index" :label="item.label" :name="item.name">
                  <el-row class="search-box">
                     <el-row class="search-row">
                        <el-row class="search-item">
                           <label>协议单位 ：</label>
                           <el-input class="width-180" placeholder="请输入协议单位" v-model="unitName" clearable></el-input>
                        </el-row>
                        <el-row class="search-item">
                           <label>联系人 ：</label>
                           <el-input class="width-180" placeholder="请输入联系人" v-model="linkMan" clearable></el-input>
                        </el-row>
                        <el-row class="search-item">
                           <label>联系电话 ：</label>
                           <el-input class="width-180" placeholder="请输入联系电话" v-model="mobile" clearable></el-input>
                        </el-row>
                        <el-button type="primary" class="add-btn bg-gradient" @click="inquiredata">查询</el-button>
                        <el-button class="add-btn bg-gradient " @click="reset">重置</el-button>
                        <el-button class="add-btn bg-gradient " @click="dagisShow('创建','')">创建</el-button>
                     </el-row>
                  </el-row>

                  <!-- 表格 -->

                  <el-row class="table-box">

                     <el-table ref="mainTable" border :data="tableData" :stripe="true" >
                        <el-table-column label="序号" type="index" width="60" />
                        <el-table-column :label="activeName == ' '? '协议单位名称':'旅行社名称' " prop="unitName"  min-width="130" />
                        <el-table-column label="联系人" prop="linkMan" min-width="100" />
                        <el-table-column label="联系电话" prop="mobile"  width="120" />
                        <el-table-column label="价格模板名" prop="templateName"  width="130" />
                        <el-table-column label="管理员人数" prop="employeeNum"  width="130" />
                        <el-table-column label="创建时间" prop="createTime" min-width="100" />
                       <el-table-column label="状态"  width="100" >
                         <template slot-scope="scope">
                           <span>{{scope.row.status == "YES"?"禁用":"启用"}}</span>
                         </template>

                       </el-table-column>
                        <el-table-column label="备注" prop="remark"  width="100" />
                        <el-table-column label="操作"  min-width="200" >
                           <template slot-scope="scope">
                              <el-button type="text" @click="permanagement(scope.row)">成员管理</el-button>
                              <el-button type="text" @click="temmanagement(scope.row)">模版管理</el-button>
                             <el-button type="text" @click="dagisShow('编辑',scope.row)">编辑</el-button>
                              <el-button type="text" @click="deleteitem(scope.row.id)">删除</el-button>
                           </template>
                        </el-table-column>


                     </el-table>
                     <!-- 分页 -->
                     <pagination :total="total" :page-size="limit" v-if="pageshow" @handleSizeChangeSub="couponPageNum" @handleCurrentChangeSub="couponCurrPage"/>
                  </el-row>
               </el-tab-pane>
            </el-tabs>
         </div>
         <!--成员管理-->
         <el-dialog title="成员管理" :visible.sync="isadminShow" :before-close="handleClose"  width="50%">
         <el-form style="width: 80%;margin: 0 auto"   @submit.native.prevent>
            <el-form-item label="成员管理" label-width="100">
              <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="memberlistIds"
                  :trigger-on-focus="false"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  @select="handleSelect">
                <i  class="el-icon-edit el-input__icon" slot="suffix" >
                </i>
                <template slot-scope="{ item }" >
                  <div class="name" >{{ item.value }}</div>
<!--                  <span class="addr">{{ item.label }}</span>-->
                </template>
              </el-autocomplete>
              <el-form-item label="已选成员" label-width="100">
                <el-tag v-for="i in taglist" closable :disable-transitions="false" @close="handletag(i)" :key="i.id">{{i.value}}</el-tag>
              </el-form-item>
            </el-form-item>
         </el-form>
            <div slot="footer" class="dialog-footer" >
               <el-button @click="handleClose">取消</el-button>
               <el-button class="bg-gradient" type="primary" @click="deletemeber()">确定</el-button>
            </div>
         </el-dialog>
         <!--模板管理-->
         <el-dialog title="模板管理" :visible.sync="istempShow" :before-close="handleClose"  width="500px">
            <el-form style="width: 80%;margin: 0 auto" >
               <el-form-item label="选择模板" label-width="100">
                  <el-select v-model="value" placeholder="请选择">
                     <el-option label="固定折扣" value="固定折扣"></el-option>
                     <el-option v-for="(item,index) in templatelist" :key="index" :label="item.templateName" :value="item.id"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="输入折扣" label-width="100" v-if="value==='固定折扣'">
                  <el-input-number v-model="num" :min="1" :precision="0" :max="100" label="折扣"></el-input-number> %
               </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
               <el-button @click="handleClose">取消</el-button>
               <el-button class="bg-gradient" type="primary" @click="choosetemplate()">确定</el-button>
            </div>
         </el-dialog>

         <!-- 创建/编辑 弹窗 -->
         <el-dialog :title="dialogTitle" :visible.sync="isShow" :before-close="handleClose"  width="550px">
            <el-form class="form-dialog-box" :model="formData" :rules="rules" ref="ruleForm">
               <el-form-item label="旅行社：" prop="unitName">
                  <el-input  class="width-300" v-model="formData.unitName" placeholder="请输入旅行社名称" clearable></el-input>
               </el-form-item>
               <el-form-item label="联系人：" prop="linkMan">
                  <el-input  class="width-300" v-model="formData.linkMan" placeholder="请输入联系人" clearable> </el-input>
               </el-form-item>

               <el-form-item label="联系电话：" prop="mobile">
                  <el-input  class="width-300" v-model="formData.mobile" placeholder="请输入联系电话" clearable> </el-input>
               </el-form-item>
               <el-form-item label="状态" v-if="dialogTitle == '编辑' ">
                  <el-radio v-model="formData.status" label="NO">启用</el-radio>
                  <el-radio v-model="formData.status" label="YES">禁用</el-radio>
               </el-form-item>
               <el-form-item label="备注：">
                  <el-input type="textarea" class="width-300" v-model="formData.remark" maxlength="300"  rows="4" show-word-limit clearable></el-input>
               </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" >
               <el-button @click="handleClose">取消</el-button>
               <el-button class="bg-gradient" type="primary" @click="submitForm('ruleForm')">确定</el-button>
            </div>
         </el-dialog>
      </el-row>
   </div>
</template>

<script>
import {urlObj} from '@/api/interface'
import { AgreementUnit } from "@/api/interface/data";
import { pms } from "@/api/interface/pms";

import common, {  exportFile, dateFactory} from '@/common/js/common'
import { mapState } from "vuex";
export default {
   data() {
      return {
         that: this,
         pageshow: true,
         isadminShow:false,
         isShow:false,
         loading: false,
         istempShow:false,
         activeName: ' ',   // tab栏
         dialogTitle:'',
         tabslist: [//tab列表
            {name: ' ', label: '全部'},
            {name: 'NO', label: '启用'},
            {name: 'YES', label: '禁用'}
         ],
         templatelist: [],
         value:'',
         num:80,       // 折扣
         tableData:[],   //表格数据
         unitName:'', // 协议单位
         linkMan:'',   //联系人
         mobile:'', // 联系电话
         memberlistIds:null,
         oldmemberlistIds:[],
         userParam:'',
         id:'',
         taglist:[],
         url:'',
         formData:{
            unitName:'',
            linkMan:'',
            mobile:'',
            hotelId:'',
            remark:'',
            status:'',
            templateId:'',
            id:''
         },
         page: 1,
         total:0,
         list:[],
         tabindex: 0,//tab页的下标
         type: '',//商品类型
         limit: 10,
         rules:{
            unitName:[{ required: true, message: '请输入协议单位', trigger: 'blur'}],
            linkMan:[{ required: true,message: '请输入联系人', trigger: 'blur' }],
            mobile:[{ required: true, message: '请输入联系电话', trigger: 'blur' }],
         }
      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.formData.hotelId = this.hotelInfo.id
               this.hotelCode = this.hotelInfo.hotelCode
               this.getStatisticslist()
               this.getpriceTemplate()
            }
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      this.hotelId = this.hotelInfo.id
      this.formData.hotelId = this.hotelInfo.id
      // common.getDict(['order-source', 'order-status'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getStatisticslist()
      this.getpriceTemplate()
   },

   methods: {
     querySearch(queryString, cb) {
       if (queryString != ''){
         clearTimeout(this.timeout)
         this.timeout = setTimeout(()=>{
           const param = {
             companyId:this.hotelInfo.storeId,
             hotelId: this.hotelInfo.id,
             keyword:this.memberlistIds
           }
           const url = pms.getUnBindEmployeeList+ `?limit=10&page=1`
           this.$axios.post(url, param).then((res) => {
             if(res.success){
               this.total = res.total
               this.memberlist = res.records
               this.memberlist = this.memberlist.filter(item=>{
                 return item.hasOwnProperty('nickName')
               })
               this.list = []
               this.memberlist.forEach((item,index)=>{
                 this.list.push({"label":item.id,"value":item.nickName,'mobile':item.mobile})
               })
               this.restaurants  = this.list
               var restaurants = this.restaurants;
               var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
               // 调用 callback 返回建议列表的数据
               cb(results);
             }
           })
         },1500)

       }
     },
     //返回查询到的数据
     createFilter(queryString) {
       if (queryString!=''){
         return (restaurant) => {
           return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1 || restaurant.mobile.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
         };
       }

     },
     //选中下拉列表
     handleSelect(item) {
       this.memberlistIds = ''
       this.taglist.push(item)
     },

      //查询
      inquiredata(){
         this.pageshow = false
         this.page = 1
         this.$nextTick(() => {
            this.pageshow = true
         })
         this.getStatisticslist()
      },
     //选择模板
     choosetemplate(){
        if (this.value == ''){
          this.$message.error("请选择模板")
          return
        }
        this.url = AgreementUnit.updateAgreementUnit
        const param = {
         id:this.id,
        }
        if (this.value == '固定折扣') {
         param.ratio = this.num
        } else {
         param.templateId = this.value
        }
         this.$axios.post(this.url, param).then(res => {
            if (res.success) {
               this.istempShow = false
               this.$message.success('保存成功')
               this.getStatisticslist()
            }
         })
     },
     //获取模板列表
     getpriceTemplate(){
       let url = pms.getpriceTemplate,
           params = {
             hotelId:this.hotelInfo.id
           }
       this.$axios.post(url, params,'json').then( res => {
         if(res.status){
           this.templatelist = res.records

         }
       })
     },

      //新增、编辑弹窗
      dagisShow(type,row){
         this.dialogTitle = type

        if (row !=''){
            this.formData.id = row.id
            this.formData.unitName = row.unitName
            this.formData.linkMan =  row.linkMan
            this.formData.mobile =  row.mobile
            this.formData.remark =  row.remark
            this.formData.status = row.status
            this.formData.templateId = row.templateId

         }

        this.isShow = true
      },
      //编辑，新增
      submitForm(formName){
          if (this.dialogTitle == '创建'){
            this.url = AgreementUnit.addAgreementUnit
            this.formData.status = 'NO'
            delete this.formData.id
          }else{
            this.url = AgreementUnit.updateAgreementUnit
          }
         const param = this.formData
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$axios.post(this.url, param).then(res => {
                  if (res.success) {
                     this.total = res.total
                     this.tableData = res.records
                     this.isShow = false
                     this.$message.success('保存成功')
                     this.getStatisticslist()
                  }
               })
            }
         })

      },

     //获取管理人员列表
     getAgreementbyId(){
       const param = {}
       const url = AgreementUnit.getAgreementbyId+this.id
       this.$axios.post(url, param).then((res) => {
         if(res.success){
           this.taglist = []
           this.oldmemberlistIds = []
           if (res.records.length != 0){
             res.records.forEach(item=>{
               this.taglist.push({value:item.nickName,label:item.id})
               this.oldmemberlistIds.push(item.id)
             })
           }
           this.isadminShow = true

         }
       })
     },
     //添加人员
     addmember(){
       this.isadminShow = false
       const url = AgreementUnit.addEmployee
       let list = []
       this.taglist.forEach(i=>{
         list.push(i.label)
       })
       const param = {
         unitId:this.id,
         userIds:list.join(',')
       }
       this.$axios.post(url, param).then((res) => {
         if(res.success){
           this.getStatisticslist()
           this.$message.success('保存成功')
         }
       })
     },
      //删除标签
     handletag(tag){
       this.taglist.splice(this.taglist.indexOf(tag), 1)
     },
     //删除人员
     deletemeber(){
        let list = []
         this.oldmemberlistIds.forEach(i=>{
             if (this.taglist.indexOf(i) < 0)list.push(i)
       })
       if (list.length !=0){
         const url = AgreementUnit.deleteEmployee
         const param = {
           unitId:this.id,
           userIds:list.join(',')
         }
         this.$axios.post(url, param).then((res) => {
           if(res.success){
             this.isadminShow = false
             this.taglist.forEach((item,index)=>{
               this.oldmemberlistIds.forEach(i=>{
                 if (item == i)this.taglist.splice(index,1)
               })
             })
             if (this.taglist.length != 0){
               this.addmember()
             }else{
               this.getStatisticslist()
               this.$message.success('保存成功')
             }
           }
         })
       }else{
         this.taglist.forEach((item,index)=>{
           this.oldmemberlistIds.forEach(i=>{
             if (item == i)this.taglist.splice(index,1)
           })
         })
         if (this.taglist.length != 0){
           this.addmember()
         }
       }

     },

      //弹窗关闭事件
      handleClose(){
         this.formData.unitName = ''
         this.formData.linkMan = ''
         this.formData.mobile = ''
         this.formData.remark = ''
         this.formData.state = ''
         this.id = ''
         this.formData.id = ''
         this.value = ''
         this.isShow = false
         this.isadminShow = false
         this.istempShow = false
      },
     //重置
     reset() {
       this.pageshow = false
       this.page = 1
       this.unitName = ''
       this.linkMan = ''
       this.mobile = ''
       this.$nextTick(() => {
         this.pageshow = true
       })
      this.getStatisticslist()
     },
      //tab页面切换
      handleClick(tab) {
         this.unitName = ''
         this.linkMan = ''
         this.mobile = ''
         this.inquiredata()
      },
      //人员管理
      permanagement(row){
         this.id = row.id
        this.getAgreementbyId()
      },
      //模板管理
      temmanagement(row){
         this.istempShow = true
         this.id = row.id
         this.value = row.templateId || '固定折扣'
         this.num = row.ratio
      },
      //修改
      edmit(id){

      },
      //删除
      deleteitem(id){
         this.$confirm('删除后，该旅行社的价格信息和管理人员设置将会全部删除！请确认后删除!', '提示', {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
           // id: id,isDelete:'YES'
            const param = {}
            const url = AgreementUnit.deleteUnit+id
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: '删除成功',
                     type: 'success'
                  })
                  this.getStatisticslist()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         })
      },
      //协议单位列表
      getStatisticslist() {
         const url = AgreementUnit.getAgreementPage + `?limit=${this.limit}&page=${this.page}`
         const param = {
            hotelId: this.hotelInfo.id,
            unitName:this.unitName,
            linkMan:this.linkMan,
            mobile:this.mobile,
            status:this.activeName,

         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records

            }
         })
      },

      // 改变每页数
      couponPageNum(num) {
         this.limit = num
         this.getStatisticslist()
      },
      // 改变当前页
      couponCurrPage(num) {
         this.page = num
         this.getStatisticslist()
      },


   },
   filters: {
      filterStatus(val) {
         switch (val) {
            case 'SUCCESS':
               return val = '支付成功'
            case 'FAIL':
               return val = '支付失败'
            case 'TO_BE_DIVIDED':
               return val = '待分账'
            case 'CANCEL':
               return val = '已取消'
         }
      },
      filterOrderType(val, that) {
         let dictData = sessionStorage.getItem('dictData');
         if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
      },
   },
}
</script>

<style lang="scss" scoped>
.cont {
   .search-box {
      display: flex;

      .search-row {
         .search-item {
            margin: 0 100px 0 0;
         }

         .group-box {
            ::v-deep .el-radio-button__inner {
               background: transparent;
            }
         }
      }
   }
   .table-box {
      .add-btn {
         margin-bottom: 10px;
         float: right
      }
      .add-btn {
         margin-bottom: 30px;
         float: right;
         width: 98px
      }
      .member-info {
         display: flex;
         img {
            width: 60px;
            margin-right: 10px
         }
      }
      .right-tabbox-newnotice {
         margin-top: 40px;
      }
   }
   .des {
      color: red;
      margin-right: 5px
   }
   .mg15 {
      margin: 0 15px;
      width: 98px;
   }
   .mg72 {
      margin-left: 72px;
   }
}

/deep/ .el-tabs__nav-scroll {
   margin-bottom: 15px;
}

/deep/ .el-table th.gutter {
   /*
   * 解决element-ui 表格篡位的问题 👇
   */
   display: table-cell !important;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
   // text-align: center;
   border-bottom: 1px solid #EBEEF5;

}

/deep/ .el-table th.is-leaf, .el-table td {
   // text-align: center;
   background: #f5f8f9;
}
</style>
